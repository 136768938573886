import React from 'react';
import { Button, Form, Input, Spin } from "antd";
import { useLocation, useNavigate} from "react-router-dom";
import {useUpdatePasswordMutation} from "../../redux/slices/userSlice";
import Swal from 'sweetalert2';


export default function UpdatePassword() {
    const [form] = Form.useForm();
    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const userId = queryParams.get("user_id");
    const [UpdatePassword, { isLoading: isOrderLoading }] = useUpdatePasswordMutation();


    const onFinish = (value) => {
        let formdata = {
            "user_id": userId,
            "password": value?.password,
            "token":token
        }

        UpdatePassword({value:formdata}).then((response)=>{

            if(response?.error){
                Swal.fire({
                    title: "Password update failed",
                    text: `${response?.data?.error?.message}`,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }else{
                Swal.fire({
                    title: "Password Updated!",
                    text: `${response?.data?.message}`,
                    icon: "success",
                    confirmButtonText: "OK",
                }).then(()=>{
                    navigate("/login")
                });

            }
            
        }).catch((error)=>{
            Swal.fire({
                title: "Password Update Failed",
                text: "Unable to update password.",
                icon: "error",
                confirmButtonText: "OK",
            });
        })




    }

    const onFinishFailed = (error) => {
        console.error({ error })
    }

    const validatePassword = (_, value) => {
        const uppercaseRegex = /^(?=.*[A-Z])/;
        const numberRegex = /^(?=.*\d)/;
        const minLength = 8; // Minimum password length

        if (
            value &&
            (!uppercaseRegex.test(value) ||
                !numberRegex.test(value) ||
                value.length < minLength)
        ) {
            return Promise.reject(
                new Error(
                    `Password should contain at least one uppercase letter, one number, and be at least ${minLength} characters long.`
                )
            );
        }

        return Promise.resolve();
    };
    return (
        <div className="authWrapper">
            <div className="innerWrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8">
                            <div className="card authCard">
                                <div className="card-body">
                                    <h5 className="title">Create Password</h5>
                                    <div className="formWrapper">
                                        <Form
                                            form={form}
                                            name="loginForm"
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                            layout="vertical"
                                        >
                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                className="mb-3"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your password!",
                                                    },
                                                    {
                                                        validator: validatePassword,
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    className="formControl"
                                                    placeholder="Enter password here"
                                                />
                                            </Form.Item>
                                            <Form.Item className="text-center mb-4 w-100">
                                                <Button
                                                disabled={isOrderLoading}
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="authBtn w-100"
                                                >
                                                   {isOrderLoading ? (<Spin/>):"Create Password"} 
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
