import React, { useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerSuccess } from "../../redux/slices/authSlice";
import { useUserRegisterMutation } from "../../redux/slices/userSlice";
import Swal from "sweetalert2";

const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [userRegister, { isLoading }] = useUserRegisterMutation();

    const onFinish = async (values) => {
        const response = await userRegister(values);
        if (response.error) {
            Swal.fire({
                title: response.error.data.error,
                icon: "info",
                confirmButtonText: "OK",
            });
            return;
        }
        const { user, message } = response.data;
        if (user) {
            dispatch(registerSuccess({ user: response.data }));
            Swal.fire({
                title: message,
                icon: "success",
            });
            navigate("/login");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleFormChange = () => {
        const values = form.getFieldsValue();
        const allFieldsFilled =
            values.name && values.user_name && values.email && values.password && values.confirmpassword;
        setIsButtonDisabled(!allFieldsFilled);
    };
    // Function for validating the Password
    const validatePassword = (_, value) => {
        const uppercaseRegex = /^(?=.*[A-Z])/;
        const numberRegex = /^(?=.*\d)/;
        const minLength = 8; // Minimum password length

        if (
            value &&
            (!uppercaseRegex.test(value) ||
                !numberRegex.test(value) ||
                value.length < minLength)
        ) {
            return Promise.reject(
                new Error(
                    `Password should contain at least one uppercase letter, one number, and be at least ${minLength} characters long.`
                )
            );
        }

        return Promise.resolve();
    };
    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error("Passwords do not match!"));
        },
    });
    return (
        <div className="authWrapper signUpWrap">
            <div className="innerWrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8">
                            <h4 className="cardTitle">Lifeline Inventory</h4>
                            <div className="card authCard">
                                <div className="card-body">
                                    <h5 className="title">Sign Up</h5>
                                    <div className="formWrapper">
                                        {isLoading ? (
                                            <div className="loadingWrapper">
                                                <Spin size="large" className="custom-spin"/>
                                            </div>
                                        ) : (
                                            <Form
                                                form={form}
                                                name="registerForm"
                                                onFinish={onFinish}
                                                onFinishFailed={onFinishFailed}
                                                onValuesChange={handleFormChange}
                                                autoComplete="off"
                                                layout="vertical"
                                            >
                                                <Form.Item
                                                    label="Full Name"
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter full name!",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="formControl"
                                                        placeholder="Enter your full name"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Username"
                                                    name="user_name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter username!",
                                                        },
                                                        {
                                                            validator: (_, value) => {
                                                                if (value && /\s/.test(value)) {
                                                                    return Promise.reject(new Error("Username should not contain spaces!"));
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="formControl"
                                                        placeholder="Enter your username"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Email"
                                                    name="email"
                                                    rules={[
                                                        {
                                                            type: "email",
                                                            message: "The input is not valid E-mail!",
                                                        },
                                                        {
                                                            required: true,
                                                            message: "Please input your email!",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="formControl"
                                                        placeholder="Enter your email"
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label="Password"
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input your password!",
                                                        },
                                                        {
                                                            validator: validatePassword,
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        className="formControl"
                                                        placeholder="Enter password here"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Confirm Password"
                                                    name="confirmpassword"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input your password!",
                                                        },
                                                        {
                                                            validator: validatePassword,
                                                        },
                                                        validateConfirmPassword,
                                                    ]}
                                                >
                                                    <Input.Password
                                                        className="formControl"
                                                        placeholder="Enter Confirm password here"
                                                    />
                                                </Form.Item>

                                                <Form.Item className="text-center mb-4 w-100">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="authBtn w-100"
                                                        disabled={isButtonDisabled}
                                                    >
                                                        Sign Up
                                                    </Button>
                                                </Form.Item>

                                                <p className="orText text-capitalize">
                                                    Already have an account?{" "}
                                                    <Link to={"/login"}>Login</Link>
                                                </p>
                                            </Form>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
