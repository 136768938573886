import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Select, Input, Table, Button, Radio } from 'antd';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useAddAudienceMutation, useGetAudienceMutation, useGetFilteredpatientMutation, useGetListsMutation, useImportAudienceMutation } from '../../redux/slices/patientSlice';
import Swal from "sweetalert2";
const { Option } = Select;

const Audience = () => {
    const [audienceData, setAudienceData] = useState([]);
    const [type_of_audience, setAudienceType] = useState('One Time');
    const [appointment_type, setAppointmentType] = useState([]);
    const [last_visit_days, setLastVisit] = useState(null);
    const [status, setStatus] = useState(null);
    const [campaign_type, setCampaignType] = useState(null);
    const [vendor, setVendor] = useState(null);
    const [list_id, setListId] = useState(null);
    const [name, setName] = useState('');
    const [ListIds, setListIds] = useState([]);
    const [getLists] = useGetListsMutation();
    const [importAudience] = useImportAudienceMutation();
    const [getFilteredpatient] = useGetFilteredpatientMutation();
    const [getAudience, { isLoading: AudienceLoading }] = useGetAudienceMutation();
    const [addAudience, { isLoading }] = useAddAudienceMutation();
    const [patientCount, setPatientCount] = useState(null);
    const hasRunEffect = useRef(false);
    const navigate = useNavigate();
    const [FilteredPatient, setFilteredPatient] = useState([]);
    const handleEdit = (key) => {
        navigate("/audienceuser", {
            state: { AudienceId: key },
        });
    }
    const columns = [
        { title: 'Audience Name', dataIndex: 'audienceName', key: 'audienceName' },
        { title: 'Vendor', dataIndex: 'vendor', key: 'vendor' },
        { title: 'Type of Campaign', dataIndex: 'typeOfCampaign', key: 'typeOfCampaign' },
        { title: 'Appointment Type', dataIndex: 'appointmentType', key: 'appointmentType' },
        { title: 'Audience Type', dataIndex: 'audienceType', key: 'audienceType' },
        { title: 'List ID', dataIndex: 'listId', key: 'listId' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        {
            title: "Patients",
            key: "patient",
            render: (text, record) =>
                <IoMdInformationCircleOutline
                    style={{ cursor: "pointer", color: '#b4a099' }}
                    onClick={() => handleEdit(record.key)}
                />
        },
        {
            title: "Connection",
            key: "edit",
            render: (text, record) =>
                <Button
                    className="listingButton me-1"
                    style={{ cursor: "pointer", }}
                    onClick={() => handleCreateConnection(record)}
                >Create Connection </Button>
        },
    ];

    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        fetchAudienceData();
    }, []);

    const fetchAudienceData = () => {
        getAudience()
            .then((res) => {
                const formattedData = res.data.map((item) => ({
                    key: item.id,
                    audienceName: item.name,
                    appointmentType: item.appointment_type ? JSON.parse(item.appointment_type).join(', ') : '',
                    audienceType: item.type_of_audience,
                    status: item.status,
                    typeOfCampaign: item.campaign_type,
                    vendor: item.vendor,
                    listId: item.list_id,
                    audienceData: JSON.parse(item.audience)
                }));
                setAudienceData(formattedData);
            })
            .catch((err) => {
                console.error("Error fetching audience data:", err);
            });
    };



    const handleFilterData = (appointType) => {
        setAppointmentType(appointType)
        if (last_visit_days != null) {
            handledayChange(last_visit_days, appointType)
        }
    }

    const handledayChange = (day, appointType) => {
        setLastVisit(day);
        let query = `?days=${day}&per_page=-1&service_category=${appointType ? appointType : appointment_type}`;
        getFilteredpatient(query).then((res) => {
            const formattedData = res.data.map((item) => ({
                first_name: item.patient_name,
                email: item.patient.email,

            }));
            setPatientCount(res.data.length)
            setFilteredPatient(formattedData);
        }).catch((error) => {
            console.error("Error fetching filtered patients:", error);
        });
        getLists()
            .then((response) => {
                const ids = response.data.lists.map((el) => el.list_id);
                setListIds(ids);
            })
            .catch((error) => {
                console.error("Error fetching filtered patients:", error);
            });
    };


    const handleCreateAudience = async () => {

        if (patientCount === 0) {

            Swal.fire({
                title: "Patient list not found",
                text: "Patient list count is 0.",
                icon: "info",
                confirmButtonText: "OK"
            })
        } else {
            const audienceDetails = {
                appointment_type,
                last_visit_days,
                status,
                campaign_type,
                vendor,
                list_id,
                name,
                type_of_audience,
                audience: FilteredPatient
            };

            const response = await addAudience(audienceDetails);

            if (response.error) {
                Swal.fire({
                    title: response.error.data.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
                return;
            }
            const { data, message } = response.data
            if (data) {
                Swal.fire({
                    title: message,
                    icon: "success",
                });
                fetchAudienceData();
            }
        }

    };
    const handleCreateConnection = async (audienceData) => {
        const payload = {
            import_data: audienceData.audienceData,
            list_ids: [audienceData.listId]

        }
        const response = await importAudience(payload);

        if (response.error) {
            Swal.fire({
                title: response.error.data.message,
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }
        const { data, message } = response.data
        if (data) {
            Swal.fire({
                title: message,
                icon: "success",
            });
            fetchAudienceData();
        }
    }
    return (
        <div className="wrapper orderWrapper container-fluid">
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="titleBar d-flex justify-content-between">
                        <h4>Audiences Management</h4>
                    </div>
                </div>
            </div>

            <div className='card dashboardCard mb-4'>
                <div className='card-body'>
                    <div className="row">
                        <div className="titleBar d-flex justify-content-between">
                            <h5>Create Audience</h5>
                        </div>
                        <div className="col-md-3">
                            <div className='mb-3'>
                                <label>Appointment Type</label>
                                <Select
                                    placeholder="Select Appointment Type"
                                    className="formSelect w-100"
                                    mode="multiple"
                                    onChange={handleFilterData}
                                >
                                    <Option value="Skin care">Skin Care</Option>
                                    <Option value="Injectables">Injectables</Option>
                                    <Option value="Weight Loss">Weight Loss</Option>
                                    <Option value="Other">Other</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='mb-3'>
                                <label>Last Visit</label>
                                <Select
                                    placeholder="Select Last Visit"
                                    className="formSelect w-100"
                                    onChange={(value) => {
                                        handledayChange(value); // Call handledayChange with the selected value
                                    }}
                                >
                                    <Option value="7">7 days</Option>
                                    <Option value="30">30 days</Option>
                                    <Option value="45">45 days</Option>
                                    <Option value="60">60 days</Option>
                                    <Option value="90">90 days</Option>
                                    <Option value="120">120 days</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Status</label>
                                <Select
                                    placeholder="Select Status"
                                    className="formSelect w-100"
                                    onChange={setStatus}
                                >
                                    <Option value="Live">Live</Option>
                                    <Option value="Paused">Paused</Option>
                                    <Option value="Deleted">Delete</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Type of Campaign</label>
                                <Select
                                    placeholder="Select Campaign Type"
                                    className="formSelect w-100"
                                    onChange={setCampaignType}
                                >
                                    <Option value="email">Email</Option>
                                    <Option value="sms">SMS</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Vendor</label>
                                <Select
                                    placeholder="Select Vendor"
                                    className="formSelect w-100"
                                    onChange={setVendor}
                                >
                                    <Option value="constant_contact">Constant Contact</Option>

                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>List ID</label>
                                <Select
                                    placeholder="Select List ID"
                                    className="formSelect w-100"
                                    onChange={setListId}
                                >
                                    {ListIds.map((id) => (
                                        <Option key={id} value={id}>{id}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Name</label>
                                <Input
                                    placeholder="Name Audience"
                                    className="formControl w-100"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Audience Type</label><br />
                                <Radio.Group onChange={(e) => setAudienceType(e.target.value)} value={type_of_audience}>
                                    <Radio value="Recurring">Recurring</Radio>
                                    <Radio value="One Time">One time</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="col-md-12 my-4">
                            <div className='bottomContentRow'>


                                {patientCount !== null ? (
                                    <p className='resultText'>
                                        Found {patientCount} patient{patientCount > 1 ? 's' : ''}
                                    </p>
                                ) : (<p className='resultText'></p>)}
                                {appointment_type.length > 0 && last_visit_days && status && campaign_type && vendor && name !== '' ? (
                                    <Button
                                        type="primary"
                                        className="listingButton"
                                        onClick={handleCreateAudience}
                                        loading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Create Audience
                                    </Button>
                                ) : (<></>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tableWrapper'>
                <Table
                    dataSource={audienceData}
                    columns={columns}
                    className="dashboardTable registered-user-table"
                    scroll={{ x: 'scroll' }}
                    loading={AudienceLoading}
                    pagination={true}
                    showSorterTooltip={false}
                    bordered
                />
            </div>
        </div>
    );
};

export default Audience;
