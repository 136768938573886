import React, { useEffect, useState } from 'react';
import { Input, Spin, Form, Row, Col, Button } from 'antd';
import {
    useGetSettingDetailsMutation,
    useUpdateShippingDetailsMutation, useUpdatePrescriberDetailsMutation
} from "../../redux/slices/userSlice";
import { MdEdit } from "react-icons/md";
import { MdRefresh } from "react-icons/md";
import Swal from 'sweetalert2';

export default function Settings() {
    const { TextArea } = Input;
    const [getSettingDetails, { isLoading: isSettingLoading }] = useGetSettingDetailsMutation();
    const [updateShippingDetails, { isLoading: isShippingLoading }] = useUpdateShippingDetailsMutation();
    const [updatePrescriberDetails, { isLoading: isPrescriberLoading }] = useUpdatePrescriberDetailsMutation();
    const [settingDetails, setSettingDetails] = useState()
    const [shippingDisabled, setShippingDisabled] = useState(true)
    const [prescriberDisabled, setPrescriberDisabled] = useState(true)

    const getSettingdata = () => {
        getSettingDetails().then((response) => {
            setSettingDetails(response?.data)
        }).catch((error) => {
            console.error({ error })
        })
    }

    useEffect(() => {
        getSettingdata()
    }, [])

    const onFinishShipping = (value) => {
        let formData = {
            "id": 1,
            "shipping_details": {
                "city": value?.city,
                "state": value?.state,
                "recipientFirstName": value?.first_name,
                "recipientLastName": value?.last_name,
                "address": value?.address,
                "zipCode": value?.zip_code,
                "addressLine1": value?.address,
                "recipientType": value?.type
            }
        }
        updateShippingDetails(formData).then((response) => {
            getSettingdata()
            setShippingDisabled(true)
            Swal.fire({
                title: "Updated",
                text: `${response?.data?.message}`,
                icon: "success",
                confirmButtonText: "OK",
            });
        }).catch((error) => {
            getSettingdata()
            setShippingDisabled(true)
            Swal.fire({
                title: "Failed",
                text: `Unable to update shipping details.`,
                icon: "error",
                confirmButtonText: "OK",
            });
        })
    }

    const onFinishFailedShipping = (error) => {
        console.error({ error })
    }

    const onFinishPrescriber = (value) => {
        let formData = {
            "id": 1,
            "prescriber_details": {
                "npi": value?.npi,
                "firstName": value?.first_name,
                "lastName": value?.last_name
            }
        }
        updatePrescriberDetails(formData).then((response) => {
            getSettingdata()
            setPrescriberDisabled(true)
            Swal.fire({
                title: "Updated",
                text: `${response?.data?.message}`,
                icon: "success",
                confirmButtonText: "OK",
            });
        }).catch((error) => {
            getSettingdata()
            setPrescriberDisabled(true)
            Swal.fire({
                title: "Failed",
                text: `Unable to update prescriber details.`,
                icon: "error",
                confirmButtonText: "OK",
            });
        })


    }

    const onFinishFailedPrescriber = (error) => {
        console.error({ error })
    }

    return (
        <>
            {isSettingLoading ? (
                <div className='spinnerWrapper'>
                    <Spin size="medium" className='d-flex justify-content-center align-items-center' />
                </div>
            ) : (
                <>
                    <div className="wrapper settingWrapper">
                        <div className="container-fluid">
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <div className="titleBar">
                                        <h4>Shipping Details</h4>
                                        <Button onClick={() => setShippingDisabled(false)} icon={<MdEdit />}>Edit</Button>
                                    </div>
                                </div>

                                <Form
                                    name="shippingForm"
                                    layout="vertical"
                                    initialValues={{
                                        first_name: settingDetails?.shipping_details?.recipientFirstName,
                                        last_name: settingDetails?.shipping_details?.recipientLastName,
                                        type: settingDetails?.shipping_details?.recipientType,
                                        zip_code: settingDetails?.shipping_details?.zipCode,
                                        city: settingDetails?.shipping_details?.city,
                                        state: settingDetails?.shipping_details?.state,
                                        address: settingDetails?.shipping_details?.addressLine1,
                                    }}
                                    onFinish={onFinishShipping}
                                    onFinishFailed={onFinishFailedShipping}
                                    autoComplete="off"
                                    className='shippingDetailsForm'
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Item
                                                name="first_name"
                                                label="First Name"
                                                rules={[{ required: true, message: "Please enter first name." }]}
                                            >
                                                <Input disabled={shippingDisabled} placeholder="Enter first name" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                name="last_name"
                                                label="Last Name"
                                                rules={[{ required: true, message: "Please enter last name." }]}
                                            >
                                                <Input disabled={shippingDisabled} placeholder="Enter last name" />
                                            </Form.Item>
                                        </div>
                                        <div style={{display:"none"}} className="col-md-4">
                                            <Form.Item
                                                name="type"
                                                label="Type"
                                                rules={[{ required: true, message: "Please enter type." }]}
                                            >
                                                <Input disabled placeholder="Enter type" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                name="zip_code"
                                                label="Zip Code"
                                                rules={[{ required: true, message: "Please enter zip code." }]}
                                            >
                                                <Input disabled={shippingDisabled} placeholder="Enter zip code" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Item
                                                name="city"
                                                label="City"
                                                rules={[{ required: true, message: "Please enter city." }]}
                                            >
                                                <Input disabled={shippingDisabled} placeholder="Enter city" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                name="state"
                                                label="State"
                                                rules={[{ required: true, message: "Please enter state." }]}
                                            >
                                                <Input disabled={shippingDisabled} placeholder="Enter state" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Col span={24}>
                                            <Form.Item
                                                name="address"
                                                label="Address"
                                                rules={[{ required: true, message: "Please enter address." }]}
                                            >
                                                <TextArea disabled={shippingDisabled} rows={6} placeholder="Enter address" />
                                            </Form.Item>
                                        </Col>
                                    </div>
                                    {!shippingDisabled ? (
                                        <div className="actionWrapper">
                                            <Form.Item>
                                                <Button disabled={isShippingLoading} loading={isShippingLoading} icon={<MdRefresh />} type="primary" htmlType="submit" block>
                                                    Update Shipping Details
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    ) : (<></>)}
                                </Form>
                                <div className="col-md-12 mt-4">
                                    <div className="titleBar d-flex justify-content-between">
                                        <h4>Prescriber Details</h4>
                                        <Button onClick={() => setPrescriberDisabled(false)} icon={<MdEdit />}>Edit</Button>
                                    </div>
                                </div>
                                <Form
                                    name="shippingForm"
                                    layout="vertical"
                                    initialValues={{
                                        first_name: settingDetails?.prescriber_details?.firstName,
                                        last_name: settingDetails?.prescriber_details?.lastName,
                                        npi: settingDetails?.prescriber_details?.npi
                                    }}
                                    onFinish={onFinishPrescriber}
                                    onFinishFailed={onFinishFailedPrescriber}
                                    autoComplete="off"
                                    className='shippingDetailsForm'
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Item
                                                name="first_name"
                                                label="First Name"
                                                rules={[{ required: true, message: "Please enter first name." }]}
                                            >
                                                <Input disabled={prescriberDisabled} placeholder="Enter first name" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                name="last_name"
                                                label="Last Name"
                                                rules={[{ required: true, message: "Please enter last name." }]}
                                            >
                                                <Input disabled={prescriberDisabled} placeholder="Enter last name" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                name="npi"
                                                label="NPI"
                                                rules={[{ required: true, message: "Please enter type." }]}
                                            >
                                                <Input disabled={prescriberDisabled} placeholder="Enter type" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    {!prescriberDisabled ? (
                                        <div className="actionWrapper">
                                            <Form.Item>
                                                <Button disabled={isPrescriberLoading} loading={isPrescriberLoading} icon={<MdRefresh />} type="primary" htmlType="submit" block>
                                                    Update Prescriber Details
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    ) : (<></>)}

                                </Form>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
