import React, { useEffect, useState } from 'react';
import { Input, Table, Modal, Form, Row, Col, Button, Spin } from "antd";
import { useGetRegisteredUsersMutation, useUpdateUserStatusMutation, useSendUserInviteMutation,useResendInviteUserMutation } from "../../redux/slices/userSlice";
import Swal from "sweetalert2";
import moment from 'moment';
import { MdRefresh } from "react-icons/md";

export default function RegisteredUsers() {
	const [form] = Form.useForm();
	const [modalOpen, setModalOpen] = useState(false);
	const [getRegisteredUsers, { isLoading: isUserDataLoading }] = useGetRegisteredUsersMutation();
	const [updateUserStatus, { isLoading: isUpdateUserLoading }] = useUpdateUserStatusMutation();
	const [sendUserInvite, { isLoading: isSendInviteLoading }] = useSendUserInviteMutation();
	const [resendInviteUser, { isLoading: isResendMailLoading }] = useResendInviteUserMutation();
	const [userPerPage, setUserPerPage] = useState(10);
	const [userPage, setUserPage] = useState(1);
	const [userTotal, setUserTotal] = useState(1);
	const [filteredUserData, setFilteredUserData] = useState([]);
	const [userId, setUserId] = useState();

	const getUserData = (query) => {
		getRegisteredUsers(query).then((response) => {
			setFilteredUserData(response?.data?.user?.data);
			setUserTotal(response?.data?.total);
		}).catch((error) => {
			console.error({ error })
		})
	}

	useEffect(() => {
		const query = `?per_page=${userPerPage}&page=${userPage}`;
		getUserData(query)
	}, [userPerPage, userPage])



	const onFinish = (value) => {
		let formData = {
			"email": value?.email,
			"name": value?.name
		}

		sendUserInvite({ value: formData }).then((response) => {
			setModalOpen(false)
			form.resetFields()
			if (response?.error) {
				Swal.fire({
					title: "Resend Invite.",
					text: `${response?.error?.data?.message}`,
					icon: "info",
					showCancelButton: true,
					cancelButtonText: "Cancel",
					confirmButtonText: "Resend",
					reverseButtons: true,
				  }).then((result) => {
					if (result.isConfirmed) {
					  let ResendForm = {
						"email": value?.email
					}
					resendInviteUser(ResendForm).then((response)=>{
                        Swal.fire({
							title: "Resend Invite.",
							text: `${response?.data?.message}`,
							icon: "success",
						  })
					}).catch(()=>{
						Swal.fire({
							title: "Failed",
							text: `Unable to send invite`,
							icon: "error",
						  })
					})
					} else if (result.isDismissed) {
						Swal.close();
					}
				  });
			} else {
				Swal.fire({
					title: "Invite sent.",
					text: `${response?.data?.message}`,
					icon: "success",
					confirmButtonText: "OK",
				});
			}

		}).catch((error) => {
			form.resetFields()
			setModalOpen(false)
			Swal.fire({
				title: "Invite failed.",
				text: `${error?.data?.message}`,
				icon: "error",
				confirmButtonText: "OK",
			});
		})


	}

	const onFinishFailed = (error) => {
		console.error({ error })
	}

	const generatePageSizeOptions = (total) => {
		const options = [];
		const maxPageSize = Math.min(total, 100);

		for (let i = 5; i <= maxPageSize; i += 5) {
			options.push(i);
		}
		if (total > 5 && !options.includes(10)) {
			options.push(10);
		}

		if (total > 10 && !options.includes(15)) {
			options.push(15);
		}
		return options.sort((a, b) => a - b);
	};

	const pageSizeOptions = generatePageSizeOptions(userTotal);

	const onPageSizeChange = (current, size) => {
		setUserPerPage(size);
		setUserPage(1);
	};

	const handleActivatUser = (value) => {
		let formData = { "status": "active" }
		updateUserStatus({ Id: value?.id, value: formData }).then((response) => {
			const query = `?per_page=${userPerPage}&page=${userPage}`;
			getUserData(query)
			if (response?.error) {
				Swal.fire({
					title: "User Activation failed.",
					text: `${response?.error?.data?.message}`,
					icon: "error",
					confirmButtonText: "OK",
				});
			} else {
				Swal.fire({
					title: "User Activated",
					text: `${response?.data?.message}`,
					icon: "success",
					confirmButtonText: "OK",
				});
			}

		}).catch((error) => {
			Swal.fire({
				title: "User Activation failed.",
				text: `${error?.data?.message}`,
				icon: "error",
				confirmButtonText: "OK",
			});
		})
	}

	const handleDeactivatUser = (value) => {
		setUserId(value?.id)
		let formData = { "status": "deactive" }
		updateUserStatus({ Id: value?.id, value: formData }).then((response) => {
			const query = `?per_page=${userPerPage}&page=${userPage}`;
			getUserData(query);
			if (response?.error) {
				Swal.fire({
					title: "User Activation failed.",
					text: `${response?.error?.data?.message}`,
					icon: "error",
					confirmButtonText: "OK",
				});
			} else {
				Swal.fire({
					title: "User Deactivated",
					text: `${response?.data?.message}`,
					icon: "success",
					confirmButtonText: "OK",
				});
			}

		}).catch((error) => {
			Swal.fire({
				title: "User Activation failed.",
				text: `${error?.data?.message}`,
				icon: "error",
				confirmButtonText: "OK",
			});
		})
	}

	const userListColumn = [
		{
			title: 'S.No',
			dataIndex: 'sno',
			key: 'sno',
			render: (_, record, index) => (userPage - 1) * userPerPage + index + 1,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: '20%',
		},
		{
			title: 'Email ',
			dataIndex: 'email',
			key: 'email',
			width: '30%',
		},
		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
			render: (text, record) => (
				<>
					<span>Nurse</span>
				</>
			),
		},
		{
			title: 'Created On',
			dataIndex: 'created_on',
			key: 'created_on',
			render: (text, record) => (
				<>
					<span>{moment(record.created_at).format('MM/DD/YY hh:mm:ss A')}</span>
				</>
			)
		},
		{
			title: 'Last Login',
			dataIndex: 'last_login',
			key: 'last_login',
			render: (text, record) => (
				<>
					<span>{record.last_login ? (moment(record.last_login).format('MM/DD/YY hh:mm:ss A')) : "N/A"}</span>
				</>
			)
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			render: (text, record) => (
				isUpdateUserLoading && (record?.id === userId) ? (<Spin />) : (
					<div className='action-button'>
						{record?.status === "active" ? (
							<button className='deactivate-button' onClick={() => handleDeactivatUser(record)}>Deactivate</button>
						) : (
							<button className='activate-button' onClick={() => handleActivatUser(record)}>Activate</button>
						)}
					</div>
				)
			)

		},
	]

	const handleUserSearch = (value) => {
		console.info(value)
	}

	return (
		<>
			<div className="wrapper">
				<div className="container-fluid">
					<div className="row mb-5">
						<div className="col-md-12">
							<div className="titleBar titleRow">
								<h4>Registered Users</h4>
								<div className='search-invite-wrapper rightWrapper'>
									<Input
										placeholder="Search here.."
										onChange={handleUserSearch}
										className="homesearchInput titleRowInput"
									/>
									<button onClick={() => setModalOpen(true)}>
										Invite User
									</button>
								</div>

							</div>
						</div>
						<div className="col-md-12">
							<div className="tableWrapper">
								<Table
									className="registered-user-table"
									dataSource={filteredUserData}
									columns={userListColumn}
									pagination={{
										current: userPage,
										pageSize: userPerPage,
										total: userTotal,
										onChange: (page) => setUserPage(page),
										onShowSizeChange: onPageSizeChange,
										showSizeChanger: true,
										pageSizeOptions: pageSizeOptions,
									}}
									showSorterTooltip={false}
									bordered
									loading={isUserDataLoading}
									scroll={{ x: 'auto' }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				title="Invite User"
				className='invite-user-modal'
				centered
				open={modalOpen}
				onOk={() => setModalOpen(false)}
				onCancel={() => setModalOpen(false)}
				footer={false}
			>
				<Form
					name="shippingForm"
					layout="vertical"
					initialValues={true}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					className='invite-user-form'
					form={form}
				>
					{/* First and Last Name */}
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								name="name"
								label="Name"
								rules={[{ required: true, message: "Please enter name." }]}
							>
								<Input placeholder="Enter name" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="email"
								label="Email"
								rules={[{ required: true, message: "Please enter valid email.", type: "email" }]}
							>
								<Input placeholder="Enter email address" />
							</Form.Item>
						</Col>
					</Row>
					<div className="actionWrapper">
						{/* Submit Button */}
						<Form.Item>
							<Button type="primary" disabled={isSendInviteLoading} icon={<MdRefresh style={{fontSize:"20px", marginRight:"0.5rem"}} />}
                                            loading={isSendInviteLoading}  htmlType="submit" block>
								Send Invite
							</Button>
						</Form.Item>
					</div>
				</Form>
			</Modal>
		</>
	)
}
