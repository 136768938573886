import React, { useEffect, useState } from "react";
import { Layout, Menu, Modal } from "antd";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { MdCategory, MdPerson, MdAutoAwesomeMosaic, MdAssessment } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import { BsFillCartPlusFill } from "react-icons/bs";
import { AiFillProduct } from "react-icons/ai";
import { BsPeopleFill } from "react-icons/bs";
import Logo from "../../assests/images/logo.svg";
import { MdRecentActors } from "react-icons/md";
import { FaUserShield } from "react-icons/fa";
import { useSelector } from "react-redux";
import { MdChangeCircle } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { MdOutlinePendingActions } from "react-icons/md";
import { MdInventory } from "react-icons/md";
import { PiShoppingCartFill } from "react-icons/pi";
import { IoLogOut } from "react-icons/io5";
import { logout } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { FcShop } from "react-icons/fc";
import { FaClipboardList } from "react-icons/fa";

const { Sider } = Layout;

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [selectedKey, setSelectedKey] = useState();
    const [openKeys, setOpenKeys] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [logoutClicked, setLogoutClicked] = useState(false);

    useEffect(() => {
        setSelectedKey(location.pathname);
    }, [location]);


    const userData = useSelector(
        (state) => state?.persistedReducer?.user?.user
    );

    const handleOpenChange = (keys) => {
        setOpenKeys(keys);
    };

    const handleLogout = () => {
        dispatch(logout());
        sessionStorage.clear();
        navigate("/login");
    };
    const handleLogoutClick = (e) => {
        // e.preventDefault()
        setIsModalOpen(true);
        setLogoutClicked(true);
    };

    const handleProceed = () => {
        setIsModalOpen(false);
        navigate('/inventory')
    };

    const handleSkip = () => {
        if (logoutClicked) {
            setLogoutClicked(false);
            handleLogout();
        } else {
            setIsModalOpen(false);
            setLogoutClicked(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setLogoutClicked(false);
    }

    const menuIitems = [
        {
            key: "/",
            icon: <MdAssessment />,
            label: <NavLink to="/">Dashboard</NavLink>,
        },
        {
            key: "/appointments",
            icon: <FaCalendarCheck />,
            label: <NavLink to={"/appointments"}>Appointments List</NavLink>,
        },
        {
            key: "/audience",
            icon: <BsPeopleFill />,
            label: <NavLink to={"/audience"}>Audiences Management</NavLink>,
        },
        {
            key: "/category",
            icon: <MdCategory />,
            label: <NavLink to="/category">Categories</NavLink>,
        },
        {
            key: "inventory-management",
            icon: <MdInventory />,
            label: "Inventory Management",
            children: [
                {
                    key: "/inventory",
                    icon: <MdAutoAwesomeMosaic />,
                    label: <NavLink to="/inventory">Inventories List</NavLink>,
                },
                {
                    key: "/inventory-change-log",
                    icon: <MdChangeCircle />,
                    label: <NavLink to="/inventory-change-log">Inventory Change Logs</NavLink>,
                },
            ],
        },
        {
            key: "patient_orders",
            icon: <PiShoppingCartFill />,
            label: "Orders",
            children: [
                {
                    key: "/patient_list",
                    icon: <BsFillCartPlusFill />,
                    label: <NavLink to={"/patient_orders"}>Patient List</NavLink>,
                },
                {
                    key: "/pending_orders",
                    icon: <MdOutlinePendingActions />,
                    label: <NavLink to={"/pending_orders"}>Pending Orders</NavLink>,
                },
                {
                    key: "/order-list",
                    icon: <FaClipboardList />,
                    label: <NavLink to={"/order-list"}>Orders List</NavLink>,
                },

            ],
        },
        {
            key: "/allproducts",
            icon: <AiFillProduct />,
            label: <NavLink to="/allproducts">Products Catalog</NavLink>,
        },
        {
            key: "/recent_patient_list",
            icon: <MdRecentActors />,
            label: <NavLink to="/recent_patient_list">Recent Patients</NavLink>,
        },
        {
            key: "/registered_user",
            icon: <FaUserShield />,
            label: <NavLink to="/registered_user">Registered User</NavLink>,
        },
        {
            key: "/my-account",
            icon: <MdPerson />,
            label: <NavLink to={"/my-account"}>My Account</NavLink>,
        },
        {
            key: "/settings",
            icon: <IoSettings />,
            label: <NavLink to="/settings">Settings</NavLink>,
        },
        {
            key: "/logout",
            icon: <IoLogOut />,
            label: (
                <span onClick={() => handleLogoutClick()} style={{ cursor: "pointer" }}>
                    Sign Out
                </span>
            ),
        }
    ];

    const filteredMenuItems = menuIitems.filter(item => {
        if (userData?.user_role === "nurse") {
            if (item.key === "/pending_orders" || item.key === "/registered_user" || item.key === "/settings") {
                return false;
            }
            if (item.children) {
                item.children = item.children.filter(child => {
                    return !(child.key === "/pending_orders" || child.key === "/registered_user" || child.key === "/settings");
                });
            }
        }
        return true;
    });


    const handleMenuClick = ({ key, keyPath }) => {
        const isParentMenu = keyPath.length === 1;

        setSelectedKey(key);

        if (isParentMenu) {
            setOpenKeys([]);
        }
        if (key !== "/logout") {
            navigate(key);
        }

        if (window.innerWidth <= 768) {
            setCollapsed(true);
        }
    };



    return (
        <>
            <Sider
                className="sidebar"
                width={300}
                breakpoint="lg"
                collapsedWidth="0"
                collapsed={collapsed}
                onCollapse={(collapsed) => setCollapsed(collapsed)}
            >
                <div className="topSection">
                    <div className="logoWrap">
                        <Link to={"/"}>
                            <img className="img-fluid" src={Logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="menuWrap">
                        <Menu
                            mode="inline"
                            items={filteredMenuItems}
                            onClick={handleMenuClick}
                            selectedKeys={[selectedKey]}
                            openKeys={openKeys}
                            onOpenChange={handleOpenChange}
                        />
                    </div>
                </div>
            </Sider>
            <Modal
                title="Inventory Update Reminder"
                open={isModalOpen}
                onCancel={handleCloseModal}
                footer={false}
                className="inventoryModal"
            >
                <div className="modalBody">
                    <div className="inventoryIcon">
                        <FcShop />
                    </div>
                    <p>Please update your inventory</p>
                    <div className="modalFooter">
                        <button type="button" className="btn skipBtn" onClick={() => handleSkip()}>{logoutClicked ? "Logout" : "Skip"}</button>
                        <button type="button" className="btn proceedBtn" onClick={() => handleProceed()}>Proceed</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Sidebar;
