import React, { useState, useEffect, useRef } from "react";
import { Select, Input, Table, Button, Modal, Form, Col, Row, Spin } from "antd";
import { Country, State, City } from "country-state-city";
import { LuAsterisk } from "react-icons/lu";
import {
  useSaveShippingAddressMutation
} from "../../redux/slices/productSlice";
import Swal from "sweetalert2";

const Shipping = ({ selectedOrderData, setShippingModal,getOrderData }) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [saveShippingAddress, { isLoading: isShippingLoading }] = useSaveShippingAddressMutation();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const countries = Country.getAllCountries();
  const state = State?.getStatesOfCountry(selectedCountry?.key);
  const Cities = City.getCitiesOfState(
    selectedCountry?.key,
    selectedState?.key
  );

  const handleCountryChange = (isoCode) => {
    setSelectedCountry(isoCode);
  };

  // Handle State selection and send ISO code
  const handleStateChange = (isoCode) => {
    setSelectedState(isoCode);

  };

  // Handle City selection
  const handleCityChange = (cityName) => {
    setSelectedCity(cityName);
  };

  const onFinish = (value) => {

    let formData = {
      shipping: {
        "recipientType": "patient",
        "recipientLastName": value?.recipientLastName,
        "recipientFirstName": value?.recipientFirstName,
        "recipientPhone": value?.recipientPhone,
        "recipientEmail": value?.recipientEmail,
        "addressLine1": value?.addressLine1,
        "addressLine2": value?.addressLine2,
        "city": selectedCity,
        "state": selectedState?.value,
        "zipCode": value?.zipCode,
        "country": selectedCountry?.value,
        "service": 7680,
        "order_id": selectedOrderData?.id,
        "orderId": selectedOrderData?.orderId
      }
    }

    saveShippingAddress({ orderId: selectedOrderData?.orderId, shippingData: formData }).then((response) => {
      setShippingModal(false)
      getOrderData()
      
      if (response?.error) {
        Swal.fire({
          title: "Shipping Failed",
          text: "Your shipping address updation failed.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        
        Swal.fire({
          title: "Shipping Updated",
          text: "Your shipping address has been updated.",
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    }).catch((error) => {
      getOrderData()
      setShippingModal(false)
      Swal.fire({
        title: "Shipping Failed",
        text: "Your shipping address updation failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
    })

  }

  const onFinishFailed = (error) => {
    console.error({ error })
  }


  return (
    <>
      <Form
        name="shippingForm"
        layout="vertical"
        initialValues={{
          recipientFirstName: selectedOrderData?.data?.order?.patient?.firstName || "",
          recipientLastName: selectedOrderData?.data?.order?.patient?.lastName || "",
          recipientEmail: selectedOrderData?.email || "",
          recipientPhone: selectedOrderData?.data?.order?.patient?.phoneHome || "",
          addressLine1: selectedOrderData?.data?.order?.patient?.address1 || "",
          zipCode: selectedOrderData?.data?.order?.patient?.zip || "",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* First and Last Name */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="recipientFirstName"
              label="First Name"
              rules={[{ required: true, message: "Please enter first name." }]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="recipientLastName"
              label="Last Name"
              rules={[{ required: true, message: "Please enter last name." }]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>
        </Row>

        {/* Email and Phone */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="recipientEmail"
              label="Email"
              rules={[{ required: true, message: "Please enter email." }]}
            >
              <Input placeholder="Enter email address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="recipientPhone"
              label="Phone"
              rules={[{ required: true, message: "Please enter phone number." }]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
        </Row>

        {/* Address Line 1 and 2 */}
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="addressLine1"
              label="Address Line 1"
              rules={[{ required: true, message: "Please enter address." }]}
            >
              <TextArea rows={4} placeholder="Enter address" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="addressLine2" label="Address Line 2 (Optional)">
              <TextArea rows={4} placeholder="Enter additional address" />
            </Form.Item>
          </Col>
        </Row>

        {/* Country, State, City */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true, message: "Please select a country." }]}
            >
             <Select
										showSearch
										placeholder="Select Country"
										labelInValue
										onChange={handleCountryChange}
										value={selectedCountry?.key}
										className="country-select"
									>
										{countries.map((country) => (
											<Option key={country.isoCode} value={country.name} className="d-flex">
												<img
													src={`https://flagcdn.com/16x12/${country.isoCode.toLocaleLowerCase()}.png`}
													alt=""
													className="custom-option-flag"
												/>  {country.name}
											</Option>
										))}
									</Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="state" label="State">
            <Select
										showSearch
										placeholder="Select State"
										labelInValue
										onChange={handleStateChange}
										value={selectedState?.key}
										disabled={selectedCountry === null ? true : false}
									>
										{state.map((state) => (
											<Option key={state.isoCode} value={state.name}>
												{state.name}
											</Option>
										))}
									</Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="city" label="City">
            <Select
										showSearch
										placeholder="Select City"
										onChange={handleCityChange}
										value={selectedCity}
										disabled={selectedState === null ? true : false}
									>
										{Cities.map((city) => (
											<Option key={city.isoCode} value={city.name}>
												{city.name}
											</Option>
										))}
									</Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="zipCode"
              label="Zip"
              rules={[{ required: true, message: "Please enter zip code." }]}
            >
              <Input placeholder="Enter zip code" />
            </Form.Item>
          </Col>
        </Row>

        <div className="actionWrapper">
          {/* Submit Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  )
}

export default Shipping;