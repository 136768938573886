import React, { useState, useEffect } from 'react';
import { Table, Spin, Input } from "antd";
import {
    useGetOrderMutation
} from "../../redux/slices/productSlice";
import moment from 'moment';

export default function SingleOrderList() {
    const [orderData, setorderData] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderPerPage, setOrderPerPage] = useState(10);
    const [orderPage, setOrderPage] = useState(1);
    const [orderSearch, setOrderSearch] = useState('');

    const [getOrder, { isLoading: isLoadinggetOrder }] = useGetOrderMutation();

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(orderTotal);

    const handleOrderSearch = (event) => {
        setOrderPage(1)
        setOrderPerPage(10)
        setOrderSearch(event?.target?.value)
    };

    const getOrderData = (query) => {
        getOrder(query)
            .then((res) => {
                setorderData(res?.data?.data?.data);
                setOrderTotal(res?.data?.data?.total);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }

    useEffect(() => {
        let query = `?per_page=${orderPerPage}&page=${orderPage}`
        if (orderSearch !== '') {
            query += `&search=${orderSearch}`
        }
        getOrderData(query)
    }, [orderPerPage, orderPage, orderSearch]);

    const Patientdetailscolumn = [
        {
            title: "Patient Name",
            dataIndex: "patient_name",
            key: "patient_name",
            render: ((text, record) => (
                <>
                    <span>{`${record?.data?.order?.patient?.firstName} ${record?.data?.order?.patient?.lastName}`}</span>
                </>
            ))
        },
        {
            title: "Medicine",
            dataIndex: "medicine",
            key: "medicine",
            render: ((text, record) => (
                <>
                    <span>{`${record?.data?.order?.rxs[0]?.drugName}`}</span>
                </>
            ))
        },
        {
            title: "Date Last Ordered",
            dataIndex: "dateLastOrdered",
            key: "dateLastOrdered",
            render: ((text, record) => (
                <>
                    <span>{moment(record.created_at).format('MM/DD/YY hh:mm:ss A')}</span>
                </>
            ))
        },
        {
            title: "Order Quantity",
            dataIndex: "orderQuantity",
            key: "orderQuantity",
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.rxs[0]?.quantity ? record?.data?.order?.rxs[0]?.quantity : "N/A"}</span>
                </>
            ))
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: ((text, record) => (
                <>
                    <span>{record?.amount}</span>
                </>
            ))
        },
        {
            title: "Days",
            dataIndex: "days",
            key: "days",
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.rxs[0]?.daysSupply ? record?.data?.order?.rxs[0]?.daysSupply : "N/A"}</span>
                </>
            ))
        },
        {
            title: "Delivery Address",
            dataIndex: "deliveryAddress",
            key: "deliveryAddress",
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.patient?.address1 ? record?.data?.order?.patient?.address1 : "N/A"}</span>
                </>
            ))
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: ((text, record) => (
                <>
                    <span>{record?.status === "success" ? "Successfull" : "Denied"}</span>
                </>
            ))
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            render: ((text, record) => (
                <>
                    <span>{record?.denial_reason ? record?.denial_reason : "N/A"}</span>
                </>
            ))
        },
    ];


    const onPageSizeChange = (current, size) => {
        setOrderPerPage(size);
        setOrderPage(1);
    };

    return (

        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Single Order List</h4>
                            <Input
                                    placeholder="Search Order.."
                                    onChange={handleOrderSearch}
                                    className="homesearchInput titleRowInput"
                                />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="registered-user-table"
                                columns={Patientdetailscolumn}
                                dataSource={orderData}
                                pagination={{
                                    current: orderPage,
                                    pageSize: orderPerPage,
                                    total: orderTotal,
                                    onChange: (page) => setOrderPage(page),
                                    onShowSizeChange: onPageSizeChange,
                                    showSizeChanger: true,
                                    pageSizeOptions: pageSizeOptions,
                                }}
                                loading={isLoadinggetOrder}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                                bordered
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
