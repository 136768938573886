let typingState = null;

async function debounce(func, args) {
    let data = null;

    if (typingState) {
        clearTimeout(typingState);
    }
    const timeoutID = setTimeout(async () => {
        try {
            const res = await func(args);
            if (res !== null && res !== undefined) {
                data = res;
            }
        } catch (error) {
            console.error('Error during function call:', error);
        }
    }, 400);

    typingState = timeoutID;

    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (data !== null && data !== undefined) {
                clearInterval(interval);
                resolve(data);
            }
        }, 50);
    });
}

export default debounce;
