import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import moment from 'moment';
import {
    useGetPendingOrderMutation,
    useApproveOrderMutation,
    useDenyOrderMutation
} from "../../redux/slices/productSlice";
import Swal from "sweetalert2";

const SinglePendingOrders = () => {
    const [getPendingOrder, { isPendingOrderLoading }] = useGetPendingOrderMutation();
    const [orderData, setorderData] = useState([]);
    const [approveOrder, { isLoading: isApproveOrderLoading }] = useApproveOrderMutation();
    const [denyOrder, { isLoading: isDenyOrderLoading }] = useDenyOrderMutation();
    const [recordId, setRecordId] = useState(null)
    const [pendingPerPage, setPendingPerPage] = useState(10)
    const [pendingPage, setPendingPage] = useState(1)
    useEffect(() => {
        const query = `?per_page=${pendingPerPage}&page=${pendingPage}&type=single`;
        getOrderData(query)
    }, []);



    const getOrderData = (query) => {
        getPendingOrder(query)
            .then((res) => {
                setorderData(res?.data?.data?.data);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }

    const handleOrderData = (data) => {
        setRecordId(data.id);
        approveOrder({ orderId: data.id })
            .then((res) => {
                Swal.fire({
                    title: "Order Approved Successfully",
                    icon: "success",
                });
                const query = `?per_page=${10}&page=${1}&type=single`;
                getOrderData(query);
            })
            .catch((err) => {
                console.warn({ err });
            })
            .finally(() => {
                setRecordId(null); 
            });
    };
    
    const handleOrderDeny = (data) => {
        setRecordId(data.id);
        Swal.fire({
            title: "Add reason",
            text: "Add reason for order deny.",
            input: "textarea",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                let formData = {
                    order_id: data?.id,
                    denial_reason: result?.value ? result?.value : "",
                };
                denyOrder(formData)
                    .then((response) => {
                        Swal.fire({
                            title: "Order Denied Successfully",
                            text: `${response?.data?.message}`,
                            icon: "success",
                        });
                        const query = `?per_page=${10}&page=${1}&type=single`;
                        getOrderData(query).catch((err) => {
                            console.error("err", err);
                        });
                    })
                    .catch((err) => {
                        const query = `?per_page=${10}&page=${1}&type=single`;
                        getOrderData(query);
                        console.warn({ err });
                    })
                    .finally(() => {
                        setRecordId(null); 
                    });
            } else if (result.isDismissed) {
                console.log("Order denial was canceled.");
                setRecordId(null);
            }
        });
    };
    

    const ActionLoading = isApproveOrderLoading || isDenyOrderLoading;

    const Patientdetailscolumn = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: ((text, record) => (
                <>
                    <span>{`${record?.data?.order?.patient?.firstName} ${record?.data?.order?.patient?.lastName}`}</span>
                </>
            ))
        },
        {
            title: "Order By",
            dataIndex: "order_by",
            key: "order_by",
            render: ((text, record) => (
                <>
                    <span>{record.order_by}</span>
                </>
            ))
        },
        {
            title: "Date Last Ordered",
            dataIndex: "dateLastOrdered",
            key: "dateLastOrdered",
            render: ((text, record) => (
                <>
                    <span>{moment(record.created_at).format('MM/DD/YY hh:mm:ss A')}</span>
                </>
            ))
        },
        {
            title: "Order Quantity",
            dataIndex: "orderQuantity",
            key: "orderQuantity",
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.rxs[0]?.quantity ? record?.data?.order?.rxs[0]?.quantity : "N/A"}</span>
                </>
            ))
        },
        {
            title: "Delivery Address",
            dataIndex: "deliveryAddress",
            key: "deliveryAddress",
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.patient?.address1 ? record?.data?.order?.patient?.address1 : "N/A"}</span>
                </>
            ))
        },
        {
            title: "Update Order",
            dataIndex: "update_order",
            key: "update_order",
            render: ((text, record) => {
                return (
                    <div className='action-button'>

                        {ActionLoading && recordId === record?.id ? (
                            <Spin /> 
                        ) : (
                            <>
                                <button className='approve-button' onClick={() => handleOrderData(record)}>
                                    {'Approve'}
                                </button>
                                <button className='deny-button' onClick={() => handleOrderDeny(record)}>
                                    {'Deny'}
                                </button>
                            </>
                        )}
                    </div>
                );
            })
        },
    ];

   

    return (
        <>
            <div className="wrapper orderWrapper">
                <div className="container-fluid">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="titleBar d-flex justify-content-between">
                                <h4>Single Pending Orders</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-5">
                        <div className="card tableCard">
                            <div className="card-body">
                                <div className="tableWrapper registered-user-table">
                                    <Table
                                        columns={Patientdetailscolumn}
                                        dataSource={orderData}
                                        pagination={true}
                                        bordered
                                        className="mb-4 customOrderTable productTable"
                                        loading={isPendingOrderLoading}
                                        scroll={{ x: 'auto' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SinglePendingOrders;
