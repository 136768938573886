import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Upload, Select, Skeleton } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { MdHomeFilled, MdArrowBack } from "react-icons/md";
import { MdRefresh } from "react-icons/md";
import {
    useGetProductMutation,
    useEditProductMutation
} from "../../redux/slices/productSlice";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
const { TextArea } = Input;
const EditProduct = () => {
    const userData = useSelector(
        (state) => state?.persistedReducer?.user?.user
    );
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [getProduct, { isLoading }] = useGetProductMutation();
    const [editProduct, { isEditLoading }] = useEditProductMutation();
    const { productId } = location.state || {};
    useEffect(() => {
        const query = `?per_page=-1`
        getProduct(query)
            .then((res) => {
                const filtereddata = res.data.filter((item) => item.id === productId);
                if (filtereddata.length > 0) {
                    form.setFieldsValue({
                        category_id: filtereddata[0].category_id,
                        vendor_name: filtereddata[0].vendor_name,
                        company_name: filtereddata[0].company_name,
                        item_name: filtereddata[0].item_name,
                        size: filtereddata[0].size,
                        price: filtereddata[0].price,
                        in_stock: filtereddata[0].in_stock,
                        quantity: filtereddata[0].quantity,
                        sku: filtereddata[0].sku,
                        product_description: filtereddata[0].product_description,
                        minimum_quantity: filtereddata[0].low_stock_threshold,
                        expiry: filtereddata[0].expiry
                    });
                    setImageUrl(filtereddata[0].image);
                }
            })
            .catch((err) => {
                console.error(err, "err");
            });
    }, []);
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }

        return isJpgOrPng && isLt2M;
    };

    const handleImageChange = (info) => {
        if (info.fileList.length > 0) {
            if (info.file.status === "uploading") {
                setLoading(true);
                return;
            }

            if (info.file.status === "done") {
                getBase64(info.file.originFileObj, (url) => {
                    setLoading(false);
                    setImageUrl(url);
                });
            }
        } else {
            alert("no image uploaded");
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const onFinish = async (values) => {
        const formData = new FormData();
        formData.append("product_id", productId);
        formData.append("category_id", values.category_id);
        formData.append("vendor_name", values.vendor_name);
        formData.append("company_name", values.company_name);
        formData.append("item_name", values.item_name);
        formData.append("size", values.size);
        formData.append("price", values.price);
        formData.append("in_stock", values.in_stock);
        formData.append("quantity", values.quantity);
        formData.append("sku", values.sku);
        formData.append("product_description", values.product_description);
        formData.append("low_stock_threshold", values.minimum_quantity);
        formData.append("expiry", values.expiry);
        formData.append("user_id", userData?.id);

        if (values.image && values.image.file && values.image.file.originFileObj) {
            const file = values.image.file.originFileObj;
            formData.append("image", file);
        }
        const response = await editProduct({
            productdata: formData
        });
        if (response.error) {
            Swal.fire({
                title: response.error.data.message,
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }
        const { message, product } = response.data;
        if (product) {
            Swal.fire({
                title: message,
                icon: "success",
            });
            form.resetFields();
            navigate("/allproducts");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleGoHome = () => {
        navigate("/");
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Edit Products</h4>
                            <div className="buttonWrap">
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={() => handleGoBack()}
                                >
                                    <MdArrowBack /> Back
                                </button>
                                <button
                                    type="button"
                                    className="btn homeBtn"
                                    onClick={() => handleGoHome()}
                                >
                                    <MdHomeFilled /> Go to Home
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        {isLoading ? (
                            <div className="text-center my-5">
                                <Skeleton />;
                            </div>
                        ) : (
                            <div className="formWrapper">
                                <Form
                                    form={form}
                                    name="accountForm1"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    // onValuesChange={handleFormChange}
                                    autoComplete="off"
                                    layout="vertical"
                                    initialValues={{
                                        category: "Select Category",
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Category"
                                                name="category_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select category!",
                                                    },
                                                ]}
                                            >
                                                <Select className="formSelect">
                                                    <Select.Option value={1}>Injectable</Select.Option>
                                                    <Select.Option value={2}>Supplies</Select.Option>
                                                    <Select.Option value={3}>Medications</Select.Option>
                                                    <Select.Option value={4}>Skin Care</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Vendor Name"
                                                name="vendor_name"
                                                // def={"wedafsd"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter vendor name!",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="formControl"
                                                    placeholder="Enter vendor name"
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Company Name"
                                                name="company_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter company name!",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="formControl"
                                                    placeholder="Enter company name"
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Item Name"
                                                name="item_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter item name!",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="formControl"
                                                    placeholder="Enter item name"
                                                />
                                            </Form.Item>
                                        </div>


                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Size"
                                                name="size"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter size!",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="formControl"
                                                    placeholder="Enter size here"
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Price"
                                                name="price"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter price!",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="formControl"
                                                    placeholder="Enter price here"
                                                    type="number"
                                                    onKeyPress={(e) => {
                                                        if (!/[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-2">
                                            <Form.Item
                                                label="In Stock"
                                                name="in_stock"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter stock!",
                                                    },
                                                ]}
                                            >
                                                <Select className="formSelect">
                                                    <Select.Option value={1}>Yes</Select.Option>
                                                    <Select.Option value={0}>No</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Item
                                                label="Expiry Date"
                                                name="expiry"
                                               
                                            >
                                               <Input type="date" className="add-product-date-picker" />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-2">
                                            <Form.Item
                                                label="Quantity"
                                                name="quantity"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Enter quantity!",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                 className="formControl"
                                                    placeholder="Enter stock here"
														type="text"
														rules={[
															{
																validator: (_, value) =>
																	value && value.length <= 5
																		? Promise.resolve()
																		: Promise.reject(new Error("Quantity must not exceed 5 digits.")),
															},
														]}
														maxLength={5} 
														onKeyPress={(e) => {
															if (!/[0-9]/.test(e.key)) {
																e.preventDefault();
															}
														}}
														onPaste={(e) => {
															const paste = e.clipboardData.getData("text");
															if (!/^\d{0,5}$/.test(paste)) {
																e.preventDefault();
															}
														}}
													/>
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-2">
                                            <Form.Item
                                                label="Minimum Quantity"
                                                name="minimum_quantity"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter minimum quantity!",
                                                    },
                                                ]}
                                            >
                                               
                                                <Input
                                                className="formControl"
                                                    placeholder="Enter Minimum Quantity"
														type="text"
														rules={[
															{
																validator: (_, value) =>
																	value && value.length <= 5
																		? Promise.resolve()
																		: Promise.reject(new Error("Quantity must not exceed 5 digits.")),
															},
														]}
														
														maxLength={5} 
														onKeyPress={(e) => {
															if (!/[0-9]/.test(e.key)) {
																e.preventDefault();
															}
														}}
														onPaste={(e) => {
															const paste = e.clipboardData.getData("text");
															if (!/^\d{0,5}$/.test(paste)) {
																e.preventDefault();
															}
														}}
													/>
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <Form.Item
                                                label="SKU"
                                                name="sku"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your sku!",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="formControl"
                                                    placeholder="Enter your SKU"
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Image"
                                                name="image"
                                                valuePropName="avatar"
                                            >
                                                <Upload
                                                    name="avatar"
                                                    listType="picture-card"
                                                    className="avatar-uploader w-100"
                                                    showUploadList={false}
                                                    beforeUpload={beforeUpload}
                                                    onChange={handleImageChange}
                                                    customRequest={({ file, onSuccess }) => {
                                                        setTimeout(() => {
                                                            onSuccess("ok");
                                                        }, 0);
                                                    }}
                                                >
                                                    {imageUrl ? (
                                                        <img
                                                            src={imageUrl}
                                                            alt="avatar"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: 'contain'
                                                            }}
                                                        />
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-8">
                                            <Form.Item
                                                label="Product Description"
                                                name="product_description"
                                            >
                                                <TextArea
                                                    className="formControl"
                                                    placeholder="Enter description"
                                                    rows={3}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-12 text-center my-5">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="submitBtn w-auto px-4"
                                            // disabled={isButtonDisabled}
                                            icon={<MdRefresh style={{ fontSize:"20px", marginRight:"0.5rem"}} />}
                                            loading={isEditLoading}
                                            >
                                                Update Product
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProduct;