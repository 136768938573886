import React, { useEffect, useState } from 'react';
import { Table, Button, Spin, Input } from "antd";
import {
  useGetInventoryLogsMutation,
  useGetProductMutation
} from "../../redux/slices/productSlice";
import {
  useGetRegisteredUsersMutation
} from "../../redux/slices/userSlice";
import Autocomplete from 'react-autocomplete';
import { useNavigate } from "react-router-dom";


export default function InventoryChangeLogs() {
  const navigate = useNavigate();
  const [getInventoryLogs, { isLoading }] = useGetInventoryLogsMutation();
  const [getProduct] = useGetProductMutation();
  const [getRegisteredUsers] = useGetRegisteredUsersMutation();
  const [inventoryData, setInventoryData] = useState([])
  const [inventoryPerPage, setInventoryPerPage] = useState(10)
  const [inventoryPage, setInventoryPage] = useState(1)
  const [inventoryTotal, setInventoryTotal] = useState(0)
  const [productSearch, setProductSearch] = useState('')
  const [userSearch, setUserSearch] = useState('')
  const [allUsers, setAllUsers] = useState([])
  const [allProducts, setAllProducts] = useState([])

  useEffect(() => {
    let query = `?per_page=${inventoryPerPage}&page=${inventoryPage}`
    if (productSearch !== '') {
      query += `&product_name=${productSearch}`
    }
    if (userSearch !== '') {
      query += `&user_name=${userSearch}`
    }
    getInventoryLogs(query).then((response) => {
      setInventoryData(response?.data?.data)
      setInventoryTotal(response?.data?.pagination?.total)
    }).catch((error) => {
      console.error({ error })
    })
  }, [inventoryPage, inventoryPerPage, productSearch, userSearch])

  useEffect(() => {
    const query = `?per_page=-1`
    getRegisteredUsers(query).then((response) => {
      const items = response?.data?.user?.map((item) => {
        if (item?.user_name) {
          return (
            {
              label: item?.user_name,
              value: item?.user_name,
            }
          )
        }


      });
      setAllUsers(items);
    }).catch((error) => {
      console.error({ error });
    });
    getProduct(query).then((response) => {
      const items = response?.data?.map((item) => ({
        label: item?.item_name,
        value: item?.item_name,
      }));

      setAllProducts(items)
    }).catch((error) => {
      console.error({ error })
    })
  }, [])

  const filteredUsers = allUsers.filter(item =>
    item?.label?.toLowerCase()?.includes(userSearch?.toLowerCase())
  );
  const filteredProducts = allProducts.filter(item =>
    item?.label?.toLowerCase()?.includes(productSearch?.toLowerCase())
  );

  const tableColumns = [
    {
      title: "S.NO",
      dataIndex: "sno",
      key: "sno",
      render: (_, record, index) => (inventoryPage - 1) * inventoryPerPage + index + 1,

    },
    {
      title: "Product Name",
      dataIndex: "item_name",
      key: "item_name",
      render: (text, record) => (
        <span>
          {record?.product?.item_name}
        </span>
      ),

    },
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
      render: (text, record) => (
        <span>
          {record?.user?.user_name}
        </span>
      ),

    },
    {
      title: "Description ",
      dataIndex: "description ",
      key: "description ",
      render: (text, record) => (
        <span>
          {record?.description}

        </span>
      ),
    },
    {
      title: "Previous Quantity",
      dataIndex: "previous_quantity",
      key: "previous_quantity",
      render: (text, record) => (
        <span>
          {record?.quantity_before}
        </span>
      ),
    },
    {
      title: "Updated Quantity",
      dataIndex: "updated_quantity",
      key: "updated_quantity",
      render: (text, record) => (
        <span>
          {record?.quantity_after}
        </span>
      ),

    },
  ];

  const generatePageSizeOptions = (total) => {
    const options = [];
    const maxPageSize = Math.min(total, 100);

    for (let i = 5; i <= maxPageSize; i += 5) {
      options.push(i);
    }
    if (total > 5 && !options.includes(10)) {
      options.push(10);
    }

    if (total > 10 && !options.includes(15)) {
      options.push(15);
    }
    return options.sort((a, b) => a - b);
  };

  const pageSizeOptions = generatePageSizeOptions(inventoryTotal);

  const onPageSizeChange = (current, size) => {
    setInventoryPerPage(size);
    setInventoryPage(1);
  };

  const handleProductDetails = (id) => {
    navigate("/product-details", {
        state: { productId: id },
    });
};

  return (
    <div className="wrapper productCatalogWrapper">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="titleBar titleRow">
              <h4>Inventory Change Logs</h4>
              <div className="rightWrapper">
                <Autocomplete
                  className="search_products"
                  getItemValue={(item) => item.label}
                  items={filteredProducts}
                  renderItem={(item) => (
                    <div
                      key={item.value}
                      className="wrapperItem"
                    >
                      {item.label}
                    </div>
                  )}
                  value={productSearch}
                  onChange={(e) => setProductSearch(e.target.value)}
                  onSelect={(value) => setProductSearch(value)}
                  inputProps={{ placeholder: 'Search Product' }}
                />
                <Autocomplete
                  className="search_users"
                  getItemValue={(item) => item.label}
                  items={filteredUsers}
                  renderItem={(item) => (
                    <div
                      key={item.value}
                      className="wrapperItem"
                    >
                      {item.label}
                    </div>
                  )}
                  value={userSearch}
                  onChange={(e) => setUserSearch(e.target.value)}
                  onSelect={(value) => setUserSearch(value)}
                  inputProps={{ placeholder: 'Search User' }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="tableWrapper">
              <Table
                className="productTable"
                columns={tableColumns}
                dataSource={inventoryData}
                pagination={{
                  current: inventoryPage,
                  pageSize: inventoryPerPage,
                  total: inventoryTotal,
                  onChange: (page) => setInventoryPage(page),
                  onShowSizeChange: onPageSizeChange,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOptions,
                }}
                loading={isLoading}
                showSorterTooltip={false}
                bordered
                scroll={{ x: 'auto' }}
                onRow={(record) => ({
                  onClick: () => handleProductDetails(record?.product?.id)
              })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
