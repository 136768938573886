import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api_Url } from "../../Helper";
const commonHeaders = {
  Accept: "application/json",
};
export const productSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: Api_Url }),
  endpoints: (builder) => ({
    getProduct: builder.mutation({
      query: (value) => {
        return {
          url: `/products${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    addProduct: builder.mutation({
      query: (productdata) => {
        return {
          url: "/products",
          method: "POST",
          body: productdata,
          headers: commonHeaders,
        };
      },
    }),
    updateProduct: builder.mutation({
      query: ({value}) => {
        return {
          url: "/products/update",
          method: "PUT",
          body: value,
          headers: commonHeaders,
        };
      },
    }),
    editProduct: builder.mutation({
      query: ({productdata}) => {
        return {
          url: `/products/update`,
          method: "POST",
          body: productdata,
          headers: commonHeaders,
        };
      },
    }),
    deleteProduct: builder.mutation({
      query: (id) => {
        return {
          url: `/products/${id}`,
          method: "DELETE",
          headers: commonHeaders,
        };
      },
    }),
    createOrder: builder.mutation({
      query: (orderdata) => {
        return {
          url: "/orders",
          method: "POST",
          body: orderdata,
          headers: commonHeaders,
        };
      },
    }),
    getOrder: builder.mutation({
      query: (value) => {
        return {
          url: `/orders${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getPendingOrder: builder.mutation({
      query: (value) => {
        return {
          url: `/pending_orders${value ? value : ''}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getCategories: builder.mutation({
      query: () => {
        return {
          url: "/categories",
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getProductsByCategoryId: builder.mutation({
      query: (value) => {
        return {
          url: `/products/by-category/${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    generateOrder: builder.mutation({
      query: (orderdata) => {
        return {
          url: "/strive/order",
          method: "POST",
          body: orderdata,
          headers: commonHeaders,
        };
      },
    }),
    saveOrderId: builder.mutation({
      query: (orderdata) => {
        return {
          url: "/orders/update-order-id",
          method: "PUT",
          body: orderdata,
          headers: commonHeaders,
        };
      },
    }),
    saveShippingAddress: builder.mutation({
      query: ({orderId,shippingData}) => {
        return {
          url: `/strive/order/${orderId}/shipping`,
          method: "PUT",
          body: shippingData,
          headers: commonHeaders,
        };
      },
    }),
    approveOrder: builder.mutation({
      query: ({orderId}) => {
        return {
          url: `/approve_order/${orderId}`,
          method: "PUT",
          headers: commonHeaders,
        };
      },
    }),
    denyOrder: builder.mutation({
      query: (value) => {
        return {
          url: `/orders/deny`,
          method: "PUT",
          headers: commonHeaders,
          body:value
        };
      },
    }),
    getInventoryLogs: builder.mutation({
      query: (value) => {
        return {
          url: `/inventory-logs${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getProductById: builder.mutation({
      query: ({Id}) => {
        return {
          url: `/product/${Id}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),

    getBulkOrder: builder.mutation({
      query: (value) => {
        return {
          url: `/getPatientsByBulkOrder${value? value :''}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),

    approveBulkOrder: builder.mutation({
      query: (value) => {
        return {
          url: `/approve-bulk-orders`,
          method: "POST",
          headers: commonHeaders,
          body:value
        };
      },
    }),

    denyBulkOrder: builder.mutation({
      query: (value) => {
        return {
          url: `/orders/deny-bulk`,
          method: "POST",
          headers: commonHeaders,
          body:value
        };
      },
    }),

  }),
});
export const { useGetProductMutation, useAddProductMutation ,useUpdateProductMutation,useDeleteProductMutation,useCreateOrderMutation,useGetOrderMutation , useGenerateOrderMutation, useGetCategoriesMutation, useGetProductsByCategoryIdMutation, useSaveOrderIdMutation, useSaveShippingAddressMutation,useGetPendingOrderMutation,useApproveOrderMutation,useGetInventoryLogsMutation, useGetProductByIdMutation, useDenyOrderMutation, useEditProductMutation, useGetBulkOrderMutation, useApproveBulkOrderMutation,  useDenyBulkOrderMutation} = productSlice;
