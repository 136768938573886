import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdEdit, MdArrowBack, MdRefresh } from "react-icons/md";
import { Button, Input, Skeleton, Spin } from "antd";
import {
	useGetProductByIdMutation,
	useEditProductMutation
} from "../../redux/slices/productSlice";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import moment from 'moment';

const ProductDetails = () => {
	const userData = useSelector(
		(state) => state?.persistedReducer?.user?.user
	);
	const location = useLocation();
	const navigate = useNavigate();
	const [loadings, setLoadings] = useState(false);
	const [productData, setPorductData] = useState();
	const [getProductById] = useGetProductByIdMutation();
	const [editProduct] = useEditProductMutation();
	const { productId } = location.state || {};
	const [inputText, setinputText] = useState(0);
	const [isLoading, setIsLoading] = useState(false)

	const enterLoading = async (item) => {
		if (item?.quantity === parseInt(inputText)) {
			Swal.fire({
				title: "Unable to update product",
				text: "The quantity of product is the same as before and after.",
				icon: "info",
				confirmButtonText: "OK",
			});
		} else {
			setLoadings(true)
			let formData = {
				"product_id": item?.id,
				"vendor_name": item?.vendor_name,
				"company_name": item?.company_name,
				"item_name": item?.item_name,
				"product_description": item?.product_description,
				"size": "Large",
				"price": item?.price,
				"in_stock": item?.in_stock === 1 ? true : false,
				"quantity": inputText === "" ? item?.quantity : parseInt(inputText),
				"category_id": item?.category_id,
				"user_id": userData?.id
			}
			editProduct({ productdata: formData }).then((response) => {
				if (response?.error) {
					Swal.fire({
						title: "Product updation failed",
						text: `${response?.data?.error?.message}`,
						icon: "error",
						confirmButtonText: "OK",
					});
				} else {
					Swal.fire({
						title: "Product updated!",
						text: `${response?.data?.message}`,
						icon: "success",
						confirmButtonText: "OK",
					});
				}
				getProduct(productId)
				setLoadings(false)

			}).catch((error) => {
				getProduct(productId)
				setLoadings(false)
				Swal.fire({
					title: "Product updation failed",
					text: `${error?.message}`,
					icon: "error",
					confirmButtonText: "OK",
				});
			})
		}
	};

	const handleGoHome = () => {
		navigate("/edit-product", {
			state: { productId: productId },
		});
	};

	const handleGoBack = () => {
		navigate(-1);
	};


	const getProduct = (Id) => {
		setIsLoading(true)
		getProductById({ Id })
			.then((res) => {
				setinputText(res?.data?.data?.quantity)
				setPorductData(res?.data?.data)
				setIsLoading(false)
			})
			.catch((err) => {
				setIsLoading(false)
				console.error("err", err);
			});

	}

	useEffect(() => {
		getProduct(productId)
	}, []);

	return (
		<div className="wrapper">
			<div className="container-fluid">
				<div className="row mb-5">
					<div className="col-md-12">
						<div className="titleBar titleRow">
							<div className="buttonWrap product-detail-head-wrapper">
								<button
									type="button"
									className="btn backBtn"
									onClick={() => handleGoBack()}
								>
									<MdArrowBack /> Back
								</button>
								<button
									type="button"
									className="btn homeBtn"
									onClick={() => handleGoHome()}
								>
									<MdEdit /> Edit Product
								</button>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						{isLoading ? (
							<div className="text-center my-5">
								<Skeleton />;
							</div>
						) : (
							<div className="detailsWrapper">
								<div className="row">
									<div className="col-md-5">
										<div className="productImageWrap">
											{productData?.image ? (<img
												src={productData.image}
												className="img-fluid"
												alt="product"
											/>) : (
												<p className="product-image-text">Product Image</p>
											)}

										</div>
									</div>

									<div className="col-md-7 py-2">
										<div className="productNameWrap mb-4">
											<h3>{productData?.item_name}</h3>
										</div>
										<div className="row">
											<div className="col-md-6 mb-4">
												<div className="detailBox">
													<h5>In Stock</h5>
													<Input
														type="text"
														value={inputText}
														rules={[
															{
																validator: (_, value) =>
																	value && value.length <= 5
																		? Promise.resolve()
																		: Promise.reject(new Error("Quantity must not exceed 5 digits.")),
															},
														]}
														onChange={(e) => {
															if (/^\d{0,5}$/.test(e.target.value)) {
																setinputText(e.target.value);
															}
														}}
														maxLength={5} 
														onKeyPress={(e) => {
															if (!/[0-9]/.test(e.key)) {
																e.preventDefault();
															}
														}}
														onPaste={(e) => {
															const paste = e.clipboardData.getData("text");
															if (!/^\d{0,5}$/.test(paste)) {
																e.preventDefault();
															}
														}}
														className="text-center"
													/>
												</div>
											</div>
											<div className="col-md-6 mb-4">
												<div className="detailBox">
													<h5>Price</h5>
													<p>{productData?.price}</p>
												</div>
											</div>
											<div className="col-md-6 mb-4">
												<div className="detailBox">
													<h5>SKU</h5>
													<p>{productData?.sku ? productData.sku : "-"}</p>
												</div>
											</div>
											<div className="col-md-6 mb-4 d-flex justify-content-center align-items-center">
												<Button
													type="primary"
													onClick={() => enterLoading(productData)}
													className="updateDetailsBtn w-50"
													disabled={loadings}
													icon={<MdRefresh />}
													loading={loadings}
												>
													Update
												</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="logWrapper">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="logsWrap">

								<div className="bottomSection">
									<h4 className="title mb-2">Last Change to Inventory</h4>
									<ul className="logList">
										<li>
											<span className="logText">{moment(productData?.updated_at)?.format('MM/DD/YY hh:mm:ss A')}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductDetails;