import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Select } from "antd";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useGetPatientsWithAppointmentsMutation, useGetFilteredpatientMutation, useGetListsMutation } from '../../redux/slices/patientSlice';

const Patient = () => {
    const { Option } = Select;
    const navigate = useNavigate();
    const [getPatientsWithAppointments, { isLoading: isLoadingAppointments }] = useGetPatientsWithAppointmentsMutation();
    const [getFilteredpatient, { isLoading: isFilterPatientLoading }] = useGetFilteredpatientMutation();
    const [patientData, setpatientData] = useState([]);
    const [filteredPatientData, setFilteredPatientData] = useState([]);
    const [daysFilteredPatientData, setDaysFilteredPatientData] = useState([]);
    const [patientTotal, setPatientTotal] = useState(0);
    const [filteredPatientTotal, setFilteresPatientTotal] = useState(0);
    const [patientPerPage, setPatientPerPage] = useState(10);
    const [patientPage, setPatientPage] = useState(1);
    const [search, setSearch] = useState('');
    const [last_visit_days, setLastVisit] = useState(null);
    const hasRunEffect = useRef(false);


    const getPatientData = (value) => {
        getPatientsWithAppointments(value).then((response) => {
            setpatientData(response?.data?.data)
            setFilteredPatientData(response?.data?.data)
            setPatientTotal(response?.data?.total)
            setFilteresPatientTotal(response?.data?.total)
        }).catch((err) => {
            console.error("err", err);
        });
    }

    useEffect(() => {
        if (daysFilteredPatientData?.length == 0) {
            let query = `?per_page=${patientPerPage}&page=${patientPage}`;

            if (search !== '') {
                query += `&search=${search}`
            }
            getPatientData(query)
        }

    }, [patientPerPage, patientPage, search])

    const onPageSizeChange = (current, size) => {
        setPatientPerPage(size);
        setPatientPage(1);
    };

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(daysFilteredPatientData?.length > 0 ? daysFilteredPatientData?.length : patientTotal);

    const handlePatientSearch = (event) => {
        setSearch(event?.target?.value)
    };

    const handleEdit = (key) => {
        navigate("/patientappointment", {
            state: { patientId: key },
        });
    }

    const handledayChange = (day, appointType) => {
        setLastVisit(day);
        const filterData = {
            days: day,
            service_category: appointType
        }
        getFilteredpatient(filterData).then((res) => {
            setDaysFilteredPatientData(res?.data?.data);
        }).catch((error) => {
            console.error("Error fetching filtered patients:", error);
        });
    };


    const tableColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            // sorter: (a, b) => a.name.length - b.name.length,
            render: (text, record) => (
                <>
                    <span>{`${record?.first_name ? record?.first_name : record?.patient?.first_name} ${record?.last_name ? record?.last_name : record?.patient?.last_name}`}</span>
                </>
            )
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            // sorter: (a, b) => a.email.length - b.email.length,
            render: (text, record) => (
                <>
                    {record?.email ? (<span>{record?.email}</span>) : record?.patient?.email ? (<span>{record?.patient?.email}</span>) : (<span className="text-center">"N/A"</span>)}
                </>
            )
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
            // sorter: (a, b) => a.phone.length - b.phone.length,
            render: (text, record) => (
                <>
                    {record?.phone ? (<span>{record?.phone}</span>) : record?.patient?.phone ? (<span>{record?.patient?.phone}</span>) : (<span className="text-center">N/A</span>)}
                </>
            )
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            // sorter: (a, b) => a.address.length - b.address.length,
            render: (text, record) => (
                <>
                    <span>{record?.address && record?.address !== "" ? record?.address : record?.patient?.address && record?.patient?.address !== "" ? (<span>{record?.patient?.phone}</span>) : "N/A"}</span>
                </>
            )
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
            // sorter: (a, b) => a.city.length - b.city.length,
            render: (text, record) => (
                <>
                    <span>{record?.city && record?.city !== "" ? record?.city : record?.patient?.city && record?.patient?.city !== "" ? (<span>{record?.patient?.city}</span>) : "N/A"}</span>
                </>
            )
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            // sorter: (a, b) => a.state.length - b.state.length,
            render: (text, record) => (
                <>
                    <span>{record?.state && record?.state !== "" ? record?.state : record?.patient?.state && record?.patient?.state !== "" ? (<span>{record?.patient?.state}</span>) : "N/A"}</span>
                </>
            )
        },
        {
            title: "Zip",
            dataIndex: "zip",
            key: "zip",
            // sorter: (a, b) => a.zip.length - b.zip.length,
            render: (text, record) => (
                <>
                    <span>{record?.zip && record?.zip !== "" ? record?.zip : record?.patient?.zip && record?.patient?.zip !== "" ? (<span>{record?.patient?.zip}</span>) : "N/A"}</span>
                </>
            )
        },
        {
            title: "Info",
            key: "edit",
            render: (text, record) =>
                daysFilteredPatientData?.length > 0 ? (
                    <IoMdInformationCircleOutline
                        style={{ cursor: "pointer", color: '#b4a099' }}
                        onClick={() => handleEdit(record?.patient?.id)}
                    />
                ) : (
                    record?.appointments?.length > 0 ? (
                        <IoMdInformationCircleOutline
                            style={{ cursor: "pointer", color: '#b4a099' }}
                            onClick={() => handleEdit(record.id)}
                        />
                    ) : (<span className="text-center">-</span>)
                )

        },
    ];

    const isTableLoading = isLoadingAppointments || isFilterPatientLoading;



    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Patients List</h4>
                            <div className='rightWrapper'>
                                <Input
                                    placeholder="Search here.."
                                    onChange={handlePatientSearch}
                                    className="homesearchInput"
                                />
                                <div className='mb-3'>
                                    <Select
                                        placeholder="Select Last Visit"
                                        className="formSelect w-100"
                                        onChange={(value) => {
                                            handledayChange(value);
                                        }}
                                    >
                                        <Option value="7">7 days</Option>
                                        <Option value="30">30 days</Option>
                                        <Option value="45">45 days</Option>
                                        <Option value="60">60 days</Option>
                                        <Option value="90">90 days</Option>
                                        <Option value="120">120 days</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={daysFilteredPatientData?.length > 0 ? daysFilteredPatientData : filteredPatientData}
                                pagination={{
                                    current: patientPage,
                                    pageSize: patientPerPage,
                                    total: daysFilteredPatientData?.length > 0 ? daysFilteredPatientData?.length : filteredPatientTotal,
                                    onChange: (page) => setPatientPage(page),
                                    onShowSizeChange: onPageSizeChange,
                                    showSizeChanger: true,
                                    pageSizeOptions: pageSizeOptions,
                                }}
                                loading={isTableLoading}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Patient;