import React from "react";
import { Modal, Descriptions, Spin } from "antd";

const ShippingDetails = ({ selectedShippingData }) => {

    let data = selectedShippingData.shipping.shipping;

    return (
        <>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Order ID">{data?.orderId}</Descriptions.Item>
                <Descriptions.Item label="Recipient Name">
                    {data?.recipientFirstName} {data?.recipientLastName}
                </Descriptions.Item>
                <Descriptions.Item label="Recipient Email">{data?.recipientEmail}</Descriptions.Item>
                <Descriptions.Item label="Recipient Phone">{data?.recipientPhone}</Descriptions.Item>
                <Descriptions.Item label="Recipient Type">{data?.recipientType}</Descriptions.Item>
                <Descriptions.Item label="Address Line 1">{data?.addressLine1}</Descriptions.Item>
                <Descriptions.Item label="City">{data?.city}</Descriptions.Item>
                <Descriptions.Item label="State">{data?.state}</Descriptions.Item>
                <Descriptions.Item label="Zip Code">{data?.zipCode}</Descriptions.Item>
                <Descriptions.Item label="Country">{data?.country}</Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default ShippingDetails;